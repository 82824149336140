<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'personal-dashboard' }"
            >Dashboard</router-link
          >
        </li>
        <li class="breadcrumb-item active">My Leaves</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-5">
          <h2 class="title2">My Leaves</h2>
        </div>
        <div class="col text-end">
          <router-link
            :to="{ name: 'request-leave' }"
            class="btn btn-outline-primary"
            >Request Leave</router-link
          >
        </div>
      </div>
      <div class="t-layout">
        <div class="row">
          <div class="col-md-12">
            <div v-if="this.$store.state.loader">
              <loader
                object="#f74b3d"
                color1="#ffffff"
                color2="#17fd3d"
                size="5"
                speed="2"
                bg="#343a40"
                objectbg="#999793"
                opacity="80"
                disableScrolling="false"
                name="spinning"
              ></loader>
            </div>
            <div v-else class="card">
              <div class="card-header">
                <flash-message class="myCustomClass"></flash-message>
              </div>
              <div class="card-body">
                <div v-if="this.$store.state.loader">
                  <loader
                    object="#f74b3d"
                    color1="#ffffff"
                    color2="#17fd3d"
                    size="5"
                    speed="2"
                    bg="#343a40"
                    objectbg="#999793"
                    opacity="80"
                    disableScrolling="false"
                    name="spinning"
                  ></loader>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col-md-3">
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search data"
                      ></b-form-input>
                    </div>
                    <div class="col-md-9 tx-right">
                      <div class="page-number-input custom-box">
                        <label>Go to Page:</label>
                        <b-form-input
                          class="page-number"
                          v-model="currentPage"
                          type="number"
                          min="1"
                          :max="
                            leavesData.l.length > 0
                              ? Math.ceil(leavesData.l.length / perPage)
                              : 1
                          "
                        ></b-form-input>
                      </div>
                      <div class="pagination-wrapper custom-box">
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="leavesData.l.length"
                          :per-page="perPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                    </div>
                  </div>
                  <b-table
                    class="table table-custom my-leaves"
                    :items="leavesData.l"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    show-empty
                    :filter="filter"
                    sticky-header
                  >
                    <template v-slot:cell(from)="data">
                      <p :title="data.item.leavefrom">
                        {{ data.item.leavefrom }}
                      </p>
                    </template>
                    <template v-slot:cell(to)="data">
                      <p :title="data.item.leaveto">
                        {{ data.item.leaveto }}
                      </p>
                    </template>
                    <template v-slot:cell(return_date)="data">
                      <p :title="data.item.returndate">
                        {{ data.item.returndate }}
                      </p>
                    </template>
                    <template v-slot:cell(reason)="data">
                      <p :title="data.item.reason">
                        {{ data.item.reason }}
                      </p>
                    </template>
                    <template v-slot:cell(action)="data">
                      <div class="d-inline-block">
                        <div class="d-flex justify-content-md-around">
                          <router-link
                            :to="{
                              name: 'edit-leave',
                              params: { id: data.item.id },
                            }"
                            v-if="
                              data.item.status == 'Draft' &&
                              $store.state.permittedActions.includes(551)
                            "
                            class="button button1"
                            title="Finalize leave"
                            ><i class="icon-checkmark"></i
                          ></router-link>
                          <a
                            v-if="
                              data.item.status == 'Draft' &&
                              $store.state.permittedActions.includes(552)
                            "
                            class="button button1"
                            title="Delete leave"
                            @click.prevent="deleteLeave(data.item.id)"
                            ><i class="icon-bin"></i
                          ></a>
                          <a
                            v-if="
                              data.item.status == 'Pending' &&
                              $store.state.permittedActions.includes(553)
                            "
                            class="button button1"
                            title="Cancel leave"
                            @click.prevent="cancelLeave(data.item.id)"
                            ><i class="icon-cancel-circle"></i
                          ></a>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      filter: null,
      sortIconNeutral:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconDesc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconAsc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortBy: null,
      sortDesc: false,
      fields: [
        {
          key: "from",
          label: "Leave From",
          sortable: true,
          tdAttr: { "custom-label": "Leave From:" },
        },
        {
          key: "to",
          label: "Leave To",
          sortable: true,
          tdAttr: { "custom-label": "Leave To:" },
        },
        {
          key: "return_date",
          label: "Return Date",
          sortable: true,
          tdAttr: { "custom-label": "Return Date:" },
        },
        {
          key: "type",
          label: "Leave Group",
          tdAttr: { "custom-label": "Leave Group:" },
        },
        {
          key: "leave_type",
          label: "Leave Type",
          tdAttr: { "custom-label": "Leave Type:" },
        },
        {
          key: "from",
          label: "Leave From",
          tdAttr: { "custom-label": "Leave From:" },
        },
        {
          key: "to",
          label: "Leave To",
          tdAttr: { "custom-label": "Leave To:" },
        },
        {
          key: "leavedays",
          label: "Leave Days",
          sortable: true,
          tdAttr: { "custom-label": "Leave Days:" },
        },
        {
          key: "start_time",
          label: "Start time",
          tdAttr: { "custom-label": "Start time:" },
        },
        {
          key: "end_time",
          label: "End Time",
          tdAttr: { "custom-label": "End Time:" },
        },
        {
          key: "reason",
          class: "custom-width",
          tdAttr: { "custom-label": "Reason:" },
        },
        {
          key: "return_date",
          label: "Return Date",
          tdAttr: { "custom-label": "Return Date:" },
        },
        {
          key: "status",
          tdAttr: { "custom-label": "Status:" },
        },
        {
          key: "action",
          tdAttr: { "custom-label": "Action:" },
        },
      ],
    };
  },
  computed: {
    leavesData() {
      return this.$store.state.myLeavesData;
    },
  },
  created() {
    var token = localStorage.getItem("token");
    if (token == "" || token == null) {
      window.location.href = "/login";
    }
  },
  mounted() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadMyLeaves");
  },
  methods: {
    mySortCompare(a, b, key) {
      var firstDate;
      var secondDate;
      if (key == "from") {
        firstDate = new Date(a.leavefrom);
        secondDate = new Date(b.leavefrom);
      } else if (key == "to") {
        firstDate = new Date(a.leaveto);
        secondDate = new Date(b.leaveto);
      } else if (key == "return_date") {
        firstDate = new Date(a.returndate);
        secondDate = new Date(b.returndate);
      }
      if (key == "from" || key == "to" || key == "return_date") {
        firstDate = firstDate.getTime();
        secondDate = secondDate.getTime();
        return firstDate - secondDate;
      } else {
        return false;
      }
    },
    cancelLeave(id) {
      if (confirm("Are you sure, you want to cancel leave?")) {
        axios.post("/personal/leave/cancel", { id: id }).then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
          }
          this.$store.dispatch("loadMyLeaves");
        });
      }
    },
    deleteLeave(id) {
      if (confirm("Are you sure, you want to delete leave?")) {
        axios.post("/personal/leave/delete", { id: id }).then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
          }
          this.$store.dispatch("loadMyLeaves");
        });
      }
    },
  },
};
</script>
