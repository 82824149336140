var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-right rounded-3"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'personal-dashboard' }}},[_vm._v("Dashboard")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("My Leaves")])])]),_c('div',{staticClass:"content-body",staticStyle:{"min-height":"calc(100vh - 135px)"},attrs:{"id":"fullHeight"}},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col text-end"},[_c('router-link',{staticClass:"btn btn-outline-primary",attrs:{"to":{ name: 'request-leave' }}},[_vm._v("Request Leave")])],1)]),_c('div',{staticClass:"t-layout"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(this.$store.state.loader)?_c('div',[_c('loader',{attrs:{"object":"#f74b3d","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"80","disableScrolling":"false","name":"spinning"}})],1):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('flash-message',{staticClass:"myCustomClass"})],1),_c('div',{staticClass:"card-body"},[(this.$store.state.loader)?_c('div',[_c('loader',{attrs:{"object":"#f74b3d","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"80","disableScrolling":"false","name":"spinning"}})],1):_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('b-form-input',{attrs:{"type":"search","placeholder":"Search data"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('div',{staticClass:"col-md-9 tx-right"},[_c('div',{staticClass:"page-number-input custom-box"},[_c('label',[_vm._v("Go to Page:")]),_c('b-form-input',{staticClass:"page-number",attrs:{"type":"number","min":"1","max":_vm.leavesData.l.length > 0
                            ? Math.ceil(_vm.leavesData.l.length / _vm.perPage)
                            : 1},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('div',{staticClass:"pagination-wrapper custom-box"},[_c('b-pagination',{attrs:{"total-rows":_vm.leavesData.l.length,"per-page":_vm.perPage,"aria-controls":"my-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])]),_c('b-table',{staticClass:"table table-custom my-leaves",attrs:{"items":_vm.leavesData.l,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"show-empty":"","filter":_vm.filter,"sticky-header":""},scopedSlots:_vm._u([{key:"cell(from)",fn:function(data){return [_c('p',{attrs:{"title":data.item.leavefrom}},[_vm._v(" "+_vm._s(data.item.leavefrom)+" ")])]}},{key:"cell(to)",fn:function(data){return [_c('p',{attrs:{"title":data.item.leaveto}},[_vm._v(" "+_vm._s(data.item.leaveto)+" ")])]}},{key:"cell(return_date)",fn:function(data){return [_c('p',{attrs:{"title":data.item.returndate}},[_vm._v(" "+_vm._s(data.item.returndate)+" ")])]}},{key:"cell(reason)",fn:function(data){return [_c('p',{attrs:{"title":data.item.reason}},[_vm._v(" "+_vm._s(data.item.reason)+" ")])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-inline-block"},[_c('div',{staticClass:"d-flex justify-content-md-around"},[(
                            data.item.status == 'Draft' &&
                            _vm.$store.state.permittedActions.includes(551)
                          )?_c('router-link',{staticClass:"button button1",attrs:{"to":{
                            name: 'edit-leave',
                            params: { id: data.item.id },
                          },"title":"Finalize leave"}},[_c('i',{staticClass:"icon-checkmark"})]):_vm._e(),(
                            data.item.status == 'Draft' &&
                            _vm.$store.state.permittedActions.includes(552)
                          )?_c('a',{staticClass:"button button1",attrs:{"title":"Delete leave"},on:{"click":function($event){$event.preventDefault();return _vm.deleteLeave(data.item.id)}}},[_c('i',{staticClass:"icon-bin"})]):_vm._e(),(
                            data.item.status == 'Pending' &&
                            _vm.$store.state.permittedActions.includes(553)
                          )?_c('a',{staticClass:"button button1",attrs:{"title":"Cancel leave"},on:{"click":function($event){$event.preventDefault();return _vm.cancelLeave(data.item.id)}}},[_c('i',{staticClass:"icon-cancel-circle"})]):_vm._e()],1)])]}}])})],1)])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-5"},[_c('h2',{staticClass:"title2"},[_vm._v("My Leaves")])])}]

export { render, staticRenderFns }